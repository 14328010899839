import React, { useEffect, useState } from "react";
import ConversationList from "../ConversationList/ConversationList";

import SidebarBtns from "../SidebarBtns/SidebarBtns";
import { PlusIcon } from "../../icons";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Loader } from "../Loaders/Loaders";
import Error from "../Error/Error";
import { useGetConversationsQuery } from "../../services/conversationService";
import {
  setPrevSelectedConversation,
  setSelectedConversation,
} from "../../services/selectedConversation";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom"; // If you're using React Router
import Cookies from "js-cookie";

const Sidebar = ({ showSidebar, setShowSidebar, setShowWelcome }) => {
  const [newChat, setNewChat] = useState();
  const location = useLocation();
  const requestBased = Cookies.get("requestBased");

  const selectedConversation = useSelector(
    (state) => state.selectedConversation
  );

  const params = new URLSearchParams(location.search);
  const myParam = params.get("id");

  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const { data, error, isLoading, refetch } = useGetConversationsQuery(myParam ? myParam : "");

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data.length > 0) {
      setNewChat();
    }
    if (data && data.length === 0) setShowWelcome(true);
    if (myParam) setShowWelcome(true);
  }, [data, setShowWelcome, myParam]);

  const handleNewChat = (e) => {
    e.preventDefault();
    setNewChat([{ conversation_id: "nofetch", conversation_name: "New Chat" }]);
    dispatch(setPrevSelectedConversation(selectedConversation));
    dispatch(setSelectedConversation("nofetch"));
    !isDesktop && setShowSidebar(false);
  };

  const handleRetry = () => {
    refetch();
  };

  return (
    <>
      <aside
        className={`w-full sm:w-2/4  lg:w-1/4  z-10  fixed  left-0 bottom-0  z-1  flex flex-col overflow-visible  transform ease-in-out duration-300 ${
          showSidebar ? "translate-x-0" : "-translate-x-full"
        } ${
          window.mapp
            ? "top-0"
            : requestBased === "socket"
            ? "top-[91px]"
            : "top-[75px]"
        } ${requestBased === "socket" ? "bg-socket-dark" : "bg-primary-black"}`}
      >
        <SidebarHeader handleNewChat={handleNewChat} />
        <Scrollbars
          autoHide
          renderTrackHorizontal={(props) => (
            <div {...props} className="scrollbar-track-horizontal" />
          )}
          renderThumbHorizontal={(props) => (
            <div
              {...props}
              className="scrollbar-thumb-horizontal bg-gray-500 rounded-full "
            />
          )}
        >
          <div className="pb-5 px-3 pl-6">
            {isLoading ? (
              <div className="flex items-center justify-center mt-10">
                <Loader />
              </div>
            ) : error ? (
              <>
                <Error
                  msg={error.error ? error.error : error.data.detail}
                  classes={"bg-primary-white"}
                />
                <div className="text-center flex justify-center">
                  <button
                    onClick={() => handleRetry()}
                    type="button"
                    className="text-center  mt-5 text-primary-white bg-primary-red hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                  >
                    Refetch
                  </button>
                </div>
              </>
            ) : (
              <nav className="side-menu-nav flex-grow flex flex-col overflow-visible scroll-smooth">
                <div>
                  <ConversationList
                    conversations={data}
                    setShowSidebar={setShowSidebar}
                    newChat={newChat}
                  />
                </div>
              </nav>
            )}
          </div>
        </Scrollbars>

        <footer className="side-menu-footer flex-shrink-0 bg-skyblue  text-white py-5 border-t border-primary-grey">
          <SidebarBtns />
        </footer>
      </aside>
    </>
  );
};

const SidebarHeader = ({ handleNewChat }) => {
  return (
    <header className="side-menu-header flex-shrink-0 bg-skyblue p-2 pt-5 px-3 pl-6">
      <div className="flex items-center ">
        <div className="flex-1">
          <div>
            <h3 className="text-primary-white text-xl  font-semibold">
              Chat Archive
            </h3>
          </div>
        </div>
        <div className="">
          <Link
            to={"#"}
            className="text-primary-white bg-primary-black-dark"
            onClick={(e) => handleNewChat(e)}
          >
            <PlusIcon />
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Sidebar;
