import { useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
    conversationApi,

  } from "../services/conversationService";
import {  addResponse } from "../services/socketSlice";

import { current } from "@reduxjs/toolkit";


const useMessageHandler = (lastMessage, selectedConversation, myParam, appDispatch, setMsgCounter, setSocketConversationStarted, setPromt,msgCounterRef,questionIdRef) => {


  const dispatch = useDispatch();


  const handleNewConversation = useCallback((parsedData) => {
    const { conversation_id, prompt, app_id, user_id } = parsedData.data;

    appDispatch(
      conversationApi.util.updateQueryData(
        "getConversations",
        myParam ? myParam : "",
        (draftPosts) => {
          const conversationExists = draftPosts.some((conversation) => {
            return conversation.conversation_id === conversation_id;
          });

          if (!conversationExists) {
            const newConversation = {
              conversation_id,
              conversation_name: prompt,
              app_uuid: app_id,
              yum_id: user_id,
              pin: false,
              deleted: false,
            };

            draftPosts.unshift(newConversation);
            setMsgCounter(1);
            console.log("Updated draftPosts after adding new conversation:", draftPosts);
          } else {
            console.log("Conversation with conversation_id already exists:", conversation_id);
          }
        }
      )
    );
  }, [appDispatch, myParam, setMsgCounter]);

  const handleSocketHistory = useCallback((parsedData) => {
    const { conversation_id, message, sender, terminate, response_id, prompt, app_id, user_id } = parsedData.data;

    appDispatch(
      conversationApi.util.updateQueryData(
        "getSocketHistory",
        conversation_id,
        (draftPosts) => {
          if (draftPosts) {
            const copy = current(draftPosts);

            if (msgCounterRef.current === 0) {
              const newData = {
                prompt,
                conversation_name: prompt,
                conversation_id,
                app_id,
                yum_id: user_id,
                visible: terminate,
                messages: [
                  {
                    response_id,
                    message: message.content,
                    terminate,
                    sender,
                  },
                ],
              };
              draftPosts.push(newData);
              setMsgCounter(1);
            } else {
              const newResp = {
                response_id,
                message: message.content,
                terminate,
                sender,
              };

              const updated = copy.map((item, index) => {
                const newObj = { ...item };
                const messages = [...newObj.messages];

                const isLast = index === copy.length - 1;

                if (isLast) {
                  messages.push(newResp);
                  newObj["messages"] = messages;
                  newObj["visible"] = terminate;
                }
                return newObj;
              });

              Object.assign(draftPosts, updated);
            }
          }
        }
      )
    );
  }, [appDispatch, msgCounterRef, setMsgCounter]);

  const handleResponse = useCallback((parsedData) => {
    const { message, sender, terminate } = parsedData.data;
    const latestQuestionId = questionIdRef.current;

    if (sender && sender !== "userproxy") {
      dispatch(
        addResponse({
          responseId: latestQuestionId,
          answer: message.content,
          sender,
          terminate,
        })
      );
    }

    if (terminate === true) {
      setSocketConversationStarted(false);
      setMsgCounter(0);
      setPromt();
    }
  }, [questionIdRef, dispatch, setSocketConversationStarted, setMsgCounter, setPromt]);

  useEffect(() => {
    if (lastMessage) {
      const { data } = lastMessage;
      const parsedData = JSON.parse(data);

      if (parsedData.data) {
        if (!selectedConversation || selectedConversation === "nofetch") {
          handleNewConversation(parsedData);
        } else if (selectedConversation && selectedConversation !== "nofetch") {
          handleSocketHistory(parsedData);
        }

        handleResponse(parsedData);
      }
    }
  }, [lastMessage, selectedConversation, handleNewConversation, handleSocketHistory, handleResponse]);
};

export default useMessageHandler;
