// import "regenerator-runtime/runtime";

import { BiSolidMicrophone } from "react-icons/bi";
// import SpeechRecognition, {
//   useSpeechRecognition,
// } from "react-speech-recognition";
import React, { useEffect, useRef, useState } from "react";
import { SendIcon, UpCarpet } from "../../icons";
import { FaArrowCircleUp } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import kfcBot from "../../assets/img/kfcbot.png";
import pizzaBot from "../../assets/img/pizzabot1.png";
import { getCharacters, userName } from "../../utils/utils";

const name = userName();

const botDimensions = {
  kfc: { width: 32, height: 32 },
  pizzahut: { width: 32, height: 32 },
};
const Messager = ({
  text,
  setText,
  handleSubmit,
  myParam,
  socketConversationStarted,
}) => {
  const inputRef = useRef(null);
  const userAccess = useSelector((state) => state.userAccess);

  const [isOpen, setIsOpen] = useState(false);
  const messages = useSelector((state) => state.messages);
  const [bot, setBot] = useState(
    userAccess.brand === "kfc" ? kfcBot : pizzaBot
  );
  const [width, setWidth] = useState(32);
  const [height, setHeight] = useState(32);
  // const [islistening, setIsListening] = useState(false);
  // const [msg, setMsg] = useState("");

  // const {
  //   transcript,
  //   listening,
  //   resetTranscript,
  //   browserSupportsSpeechRecognition,
  // } = useSpeechRecognition();

  // const handleListening = () => {
  //   if (!listening) {
  //     console.log("listening");
  //     SpeechRecognition.startListening();
  //     setIsListening(true);
  //   } else {
  //     console.log(" stopped listening");

  //     SpeechRecognition.stopListening();
  //     setIsListening(false);
  //   }
  // };
  useEffect(() => {
    // Toggle body overflow-y when the modal is mounted or unmounted
    if (isOpen) {
      document.body.style.overflowY = "auto";
    } else {
      document.body.style.overflowY = "hidden";
    }

    // Cleanup function to reset body overflow-y on component unmount
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [isOpen]);

  const handleModal = (e) => {
    if (e.key !== "Enter") {
      setIsOpen(true);
      console.log(e.key, "exs");
      // Your code to handle Enter key press goes here
    }
  };

  const handleText = (e) => {
    // inputRef.current.value = ""; // Set value to an empty string
    setText(e.target.value);
  };

  return (
    <div className="bg-white text-gray-700 border border-gray-300 p-4 ps-3 pe-4 py-3 bg-black  rounded-md shadow-lg my-5">
      <div className="flex items-center">
        <input
          className="flex-grow overflow-auto  mr-2 border-none text-base sm:text-sm outline-none focus:ring-0 border-0	"
          id="tynChatInput"
          placeholder="Send Message"
          value={text}
          onChange={(e) => handleText(e)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // Handle sending the message here (if applicable)
              // You can call a different function for sending the message
              // and prevent default behavior to avoid triggering handleModal
              e.preventDefault();
              console.log("neeraj");
              handleSubmit(e);

              // Your logic for sending message goes here
            }
          }}
          ref={inputRef}
          disabled={socketConversationStarted}
        />
        <ul className="flex gap-4">
          {/* {!myParam && (
            <li>
              <button
                style={{ fontSize: "20px" }}
                onClick={(e) => handleModal(e)}
              >
                <FaArrowCircleUp />
              </button>
            </li>
          )} */}
          <li>
            <button
              className=""
              disabled={!text && socketConversationStarted ? true : false}
              style={{ fontSize: "20px" }}
            >
              <SendIcon />
            </button>
          </li>
        </ul>
      </div>
      {isOpen &&
        createPortal(
          <div className="fixed inset-0 z-50 flex items-center justify-center shadow t-0">
            <div
              className="bg-white  max-w-4xl w-full mx-2 rounded-xl shadow-2xl"
              style={{ maxHeight: "45em", top: "0px" }}
            >
              <div className="relative bg-white rounded-lg  dark:bg-gray-700">
                <div className="flex items-center justify-between p-4 md:p-2 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Bot Chat
                  </h3>
                  <br />
                  {/* <small className="block">
                    Conversations in a Digital Realm
                  </small> */}
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="crypto-modal"
                    onClick={() => setIsOpen(false)}
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div
                  className="flex  overflow"
                  style={{
                    overflowY: "auto",
                    maxHeight: "35em",
                    background: "#f5f5f5",
                  }}
                >
                  <div className="flex-1">
                    <div className=" overflow-y-auto p-4 pb-18">
                      {messages.map((question) => (
                        <>
                          {question.responses.map((response, index) => (
                            <>
                              <div
                                style={{
                                  marginRight: index % 2 === 0 ? "30%" : "0",
                                  marginLeft: index % 2 === 0 ? "0" : "30%",
                                }}
                                key={index}
                                className={`flex mb-4 cursor-pointer ${
                                  index % 2 === 0
                                    ? "justify-start"
                                    : "justify-end"
                                }`}
                              >
                                {index % 2 === 0 ? (
                                  <>
                                    <div className="tyn-qa-avatar align-middle mx-2">
                                      <div className="tyn-media tyn-size-md">
                                        <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full bg-primary-red object-contain 	">
                                          <span className="font-medium text-primary-white ">
                                            {getCharacters(response.sender)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex max-w-96 bg-primary-black rounded-lg p-3 gap-3">
                                      <p className="text-gray-700 text-white">
                                        {response.answer}
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="flex max-w-96 bg-white text-primary-black shadow-lg rounded-lg p-3 gap-3">
                                      <p>{response.answer}</p>
                                    </div>
                                    <div className="tyn-qa-avatar align-middle mx-2">
                                      <div className="tyn-media tyn-size-md">
                                        <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full bg-primary-red object-contain 	">
                                          <span className="font-medium text-primary-white ">
                                            {getCharacters(response.sender)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>,

          document.body
        )}
    </div>
  );
};

export default Messager;
