import React, { useEffect, useState } from "react";
import {
  CopyIcon,
  ThumbsDownIcon,
  ThumbsDownIconFill,
  ThumbsUpIcon,
  ThumbsUpIconFill,
  UpCarpet,
} from "../../icons";
import kfcBot from "../../assets/img/kfcbot.png";
import pizzaBot from "../../assets/img/pizzabot1.png";

import { useSelector } from "react-redux";

import { getCharacters, userName } from "../../utils/utils";
import ScrollToBottom from "react-scroll-to-bottom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import ReactMarkdown from "react-markdown";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { IoMdChatbubbles } from "react-icons/io";

import { createPortal } from "react-dom";
import { usePostVoetesMutation } from "../../services/conversationService";

import styles from "../../assets/css/markdown.module.css";
import { Modal } from "antd";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import rehypeRaw from "rehype-raw";

const MySwal = withReactContent(Swal);

const botDimensions = {
  kfc: { width: 32, height: 32 },
  pizzahut: { width: 32, height: 32 },
};

const name = userName();

const SocketMessages = React.memo(
  ({
    socketData,
    socketConversationStarted,
    setSocketConversationStarted,
    conversationID,
  }) => {
    const requestBased = Cookies.get("requestBased");
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const myParam = params.get("id");

    const [isOpen, setIsOpen] = useState(false);
    const [botInteractions, setBotInteractions] = useState();
    const [postVotes, postVotesInfo] = usePostVoetesMutation();

    const userAccess = useSelector((state) => state.userAccess);

    const [bot] = useState(userAccess.brand === "kfc" ? kfcBot : pizzaBot);

    const [width, setWidth] = useState(32);
    const [height, setHeight] = useState(32);

    useEffect(() => {
      if (bot) {
        const brand = userAccess.brand;
        const { width, height } = botDimensions[brand];
        setWidth(width);
        setHeight(height);
      }
    }, [bot, userAccess.brand]);

    // useEffect(() => {
    //   if (socketMessages && socketMessages.length > 0) {
    //     const botInteractions = socketMessages[socketMessages.length - 1];
    //     const { prompt, responses } = botInteractions;
    //     setPrompt(prompt);
    //     setResponses(responses);
    //   }
    // }, [socketMessages]);

    const handleCopyClick = async (text) => {
      try {
        await navigator.clipboard.writeText(text);
      } catch (err) {
        console.error("Unable to copy text to clipboard", err);
      }
    };

    const openBotInteraction = (index) => {
      setBotInteractions(socketData[index]);
      setIsOpen(true);
    };

    const handleVote = (
      action,
      response_id,
      conversation_id,
      alreadyDone = false
    ) => {
      if (!alreadyDone) {
        MySwal.fire({
          title:
            action === "like"
              ? "What do you like about the response?"
              : "Why you disliked this response?",
          input: "textarea",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Submit",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "customDangerBtn",
            cancelButton: "customCancelBtn",
          },
          preConfirm: async (details) => {
            const body = {
              response_id: response_id,
              feedback: action === "like",
              feedback_details: details,
              conversation_id,
            };

            try {
              await postVotes(body).unwrap();
              return MySwal.fire({
                text: "You feedback submitted successfully",
                icon: "success",
                customClass: {
                  confirmButton: "customDangerBtn",
                },
              });
            } catch (error) {
              return MySwal.fire({
                text: error && error.data ? error.data.detail : error,
                icon: "error",
                customClass: {
                  confirmButton: "customDangerBtn",
                },
              });
            }
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          // if (result.isConfirmed) {
          //   MySwal.fire({
          //     text: "You feedback submitted successfully",
          //     icon: "success",
          //     customClass: {
          //       confirmButton: "customDangerBtn",
          //     },
          //   });
          // }
        });
      }
    };

    return (
      <ScrollToBottom
        className="flex fixed left flex-col max-h-78 custom-height"
        initialScrollBehavior="auto"
      >
        <div className="border-0 mx-6 mr-10  mt-3 justify-center ">
          {socketData.map(
            (data, index) =>
              data.visible !== false &&
              data.messages[data.messages.length - 1].sender !==
                "userproxy" && (
                <span key={index}>
                  <div className="tyn-qa-item flex gap-4 w-full">
                    <div className="tyn-qa-avatar align-middle ">
                      <div className="tyn-media tyn-size-md">
                        <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full bg-socket-light object-contain 	">
                          <span className="font-medium text-primary-white">
                            {name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="self-center bg-socket-dark text-primary-white font-normal text-sm w-full rounded-lg flex justify-between p-[10px]">
                      <p className="text-base font-medium">{data.prompt}</p>
                      <span className="float-right self-center ">
                        <UpCarpet width="28" height="28" />
                      </span>
                    </div>
                  </div>
                  <div className="tyn-qa-item flex gap-4 w-full mt-3">
                    <div className="tyn-qa-avatar">
                      <div className="tyn-qa-avatar-wrap">
                        <div className="tyn-media tyn-size-md">
                          <div className="tyn-media tyn-size-md">
                            <div
                              className={`relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full ${
                                myParam ? "bg-socket-dark" : "bg-primary-black"
                              } object-contain 	`}
                            >
                              <span className="font-medium text-primary-white ">
                                {getCharacters(
                                  data.messages[data.messages.length - 1].sender
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ans-block self-center text-primary-black  font-normal p-2 text-sm w-full group relative ">
                      <div className="text-base  gap-2">
                        {/* <ReactMarkdown className={styles.markdown}>
                          {(data.messages[data.messages.length - 1].sender !==
                            "userproxy" &&
                            data.messages[
                              data.messages.length - 1
                            ].message.endsWith("TERMINATE.")) ||
                          data.messages[
                            data.messages.length - 1
                          ].message.endsWith("TERMINATE")
                            ? data.messages[
                                data.messages.length - 1
                              ].message.slice(0, -10)
                            : data.messages[data.messages.length - 1].message} */}
                        {/* </ReactMarkdown> */}
                        <ReactMarkdown
                          children={
                            (data.messages[data.messages.length - 1].sender !==
                              "userproxy" &&
                              data.messages[
                                data.messages.length - 1
                              ].message.endsWith("TERMINATE.")) ||
                            data.messages[
                              data.messages.length - 1
                            ].message.endsWith("TERMINATE")
                              ? data.messages[
                                  data.messages.length - 1
                                ].message.slice(0, -10)
                              : data.messages[data.messages.length - 1].message
                          }
                          className={styles.markdown}
                          rehypePlugins={[rehypeRaw]}
                          components={{
                            code({
                              node,
                              inline,
                              className,
                              children,
                              ...props
                            }) {
                              const match = /language-(\w+)/.exec(
                                className || ""
                              );
                              return !inline && match ? (
                                <SyntaxHighlighter
                                  children={String(children).replace(/\n$/, "")}
                                  style={okaidia}
                                  language={match[1]}
                                  PreTag="div"
                                  {...props}
                                />
                              ) : (
                                <code className={className} {...props}>
                                  {children}
                                </code>
                              );
                            },
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column action-icons justify-center  transition-opacity duration-300 flex flex-row sm:hidden gap-2  text-center  group-hover:flex">
                        <span className="mr-0.5">
                          <button
                            className="btn btn-icon btn-md btn-pill btn-transparent"
                            onClick={() =>
                              handleVote(
                                "like",
                                data.messages[data.messages.length - 1]
                                  .response_id,
                                data?.conversation_id,
                                data.messages[data.messages.length - 1].feedback
                              )
                            }
                            style={{ fontSize: "20px" }}
                          >
                            {data.messages[data.messages.length - 1]
                              ?.feedback ? (
                              <ThumbsUpIconFill
                                color={"#e4022b"}
                                fill={"#e4022b"}
                              />
                            ) : (
                              <ThumbsUpIcon />
                            )}
                          </button>
                        </span>
                        <span className="mr-0.5">
                          <button
                            className="btn btn-icon btn-md btn-pill btn-transparent"
                            onClick={() =>
                              handleVote(
                                "dislike",
                                data.messages[data.messages.length - 1]
                                  .response_id,
                                data?.conversation_id,
                                data.messages[data.messages.length - 1]
                                  .feedback === false
                              )
                            }
                            style={{ fontSize: "20px" }}
                          >
                            {data.messages[data.messages.length - 1]
                              ?.feedback !== null &&
                            data.messages[data.messages.length - 1]
                              ?.feedback !== undefined &&
                            data.messages[data.messages.length - 1]
                              ?.feedback === false ? (
                              <ThumbsDownIconFill
                                color={"#e4022b"}
                                fill={"#e4022b"}
                              />
                            ) : (
                              <ThumbsDownIcon />
                            )}
                          </button>
                        </span>
                        <span className="mr-0.5">
                          <button
                            className="btn btn-icon btn-md btn-pill btn-transparent"
                            onClick={() =>
                              handleCopyClick(
                                data.messages[data.messages.length - 1].message
                              )
                            }
                            style={{ fontSize: "20px" }}
                          >
                            <CopyIcon />
                          </button>
                        </span>
                        <span className="mr-0.5">
                          <button
                            className="btn btn-icon btn-md btn-pill btn-transparent"
                            onClick={() => openBotInteraction(index)}
                            style={{ fontSize: "20px" }}
                          >
                            <IoMdChatbubbles />
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </span>
              )
          )}

          {isOpen &&
            createPortal(
              <Modal
                title="Multi-agent interaction"
                centered
                open={isOpen}
                onOk={() => setIsOpen(false)}
                onCancel={() => setIsOpen(false)}
                width={1000}
                bodyStyle={{ maxHeight: "550px", overflowY: "auto" }}
                footer={null}
              >
                <div className="flex-1 text-base">
                  <div className=" overflow-y-auto p-4 pb-18">
                    <div>
                      <div className="tyn-qa-item flex gap-4 w-full">
                        <div className="tyn-qa-avatar align-middle ">
                          <div className="tyn-media tyn-size-md">
                            <div
                              className={`relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full bg-primary-red object-contain bg-socket-light`}
                            >
                              <span className="font-medium text-primary-white">
                                {name}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`self-center  bg-socket-dark text-primary-white font-normal text-sm w-full rounded-lg flex justify-between p-[10px]`}
                        >
                          <p className="text-base font-medium">
                            {botInteractions?.prompt}
                          </p>
                          <span className="float-right self-center ">
                            <UpCarpet width="28" height="28" />
                          </span>
                        </div>
                      </div>

                      <div className="flex ">
                        <div
                          className="flex-1"
                          style={{
                            margin: "25px 0",
                            marginLeft: "35px",
                          }}
                        >
                          <div className="">
                            {botInteractions.messages.map(
                              ({ message: answer, sender }, index) =>
                                sender !== "userproxy" && (
                                  <div>
                                    <div
                                      style={{
                                        marginRight:
                                          index % 2 === 0 ? "30%" : "0",
                                        marginLeft:
                                          index % 2 === 0 ? "0" : "30%",
                                      }}
                                      key={index}
                                      className={`flex mb-4 cursor-pointer ${
                                        index % 2 === 0
                                          ? "justify-start"
                                          : "justify-end"
                                      }`}
                                    >
                                      {index % 2 === 0 ? (
                                        <>
                                          <div className="tyn-qa-avatar align-middle mx-2">
                                            <div className="tyn-media tyn-size-md">
                                              <div
                                                className={`relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full bg-socket-dark object-contain 	bg-socket-light`}
                                              >
                                                <span className="font-medium text-primary-white ">
                                                  {getCharacters(sender)}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="max-w-lg bg-primary-white shadow-md rounded-lg p-3 gap-3">
                                            {/* <p className="text-gray-700 text-primary-black"> */}
                                            {/* <ReactMarkdown
                                              className={styles.markdown}
                                            >
                                              {answer}
                                            </ReactMarkdown> */}
                                            <ReactMarkdown
                                              children={answer}
                                              rehypePlugins={[rehypeRaw]}
                                              className={styles.markdown}
                                              components={{
                                                code({
                                                  node,
                                                  inline,
                                                  className,
                                                  children,
                                                  ...props
                                                }) {
                                                  const match =
                                                    /language-(\w+)/.exec(
                                                      className || ""
                                                    );
                                                  return !inline && match ? (
                                                    <SyntaxHighlighter
                                                      children={String(
                                                        children
                                                      ).replace(/\n$/, "")}
                                                      style={okaidia}
                                                      language={match[1]}
                                                      PreTag="div"
                                                      {...props}
                                                    />
                                                  ) : (
                                                    <code
                                                      className={className}
                                                      {...props}
                                                    >
                                                      {children}
                                                    </code>
                                                  );
                                                },
                                              }}
                                            />
                                            {/* </p> */}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className={` max-w-lg bg-primary-black text-primary-white shadow-lg rounded-lg p-3 gap-3`}
                                          >
                                            {/* <ReactMarkdown
                                              className={styles.markdown}
                                            >
                                              {answer}
                                            </ReactMarkdown> */}
                                            <ReactMarkdown
                                              children={answer}
                                              className={styles.markdown}
                                              rehypePlugins={[rehypeRaw]}
                                              components={{
                                                code({
                                                  node,
                                                  inline,
                                                  className,
                                                  children,
                                                  ...props
                                                }) {
                                                  const match =
                                                    /language-(\w+)/.exec(
                                                      className || ""
                                                    );
                                                  return !inline && match ? (
                                                    <SyntaxHighlighter
                                                      children={String(
                                                        children
                                                      ).replace(/\n$/, "")}
                                                      style={okaidia}
                                                      language={match[1]}
                                                      PreTag="div"
                                                      {...props}
                                                    />
                                                  ) : (
                                                    <code
                                                      className={className}
                                                      {...props}
                                                    >
                                                      {children}
                                                    </code>
                                                  );
                                                },
                                              }}
                                            />
                                          </div>
                                          <div className="tyn-qa-avatar align-middle mx-2">
                                            <div className="tyn-media tyn-size-md">
                                              <div
                                                className={`relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full bg-socket-light object-contain 	`}
                                              >
                                                <span className="font-medium text-primary-white ">
                                                  {getCharacters(sender)}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>,

              document.body
            )}
        </div>
      </ScrollToBottom>
    );
  }
);

export default SocketMessages;
