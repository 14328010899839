import React, { useEffect, useState } from "react";
import { PersonUpIcon, TrashIcon } from "../../icons";

import { useDeleteConversationsMutation } from "../../services/conversationService";
import { userEmail } from "../../utils/utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  setPrevSelectedConversation,
  setSelectedConversation,
} from "../../services/selectedConversation";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

const SidebarBtns = () => {
  const dispatch = useDispatch();
  const selectedConversation = useSelector(
    (state) => state.selectedConversation
  );

  const [deleteMsg, deleteMsgInfo] = useDeleteConversationsMutation();
  const [isConfirming, setIsConfirming] = useState(false);

  const handleClick = () => {
    const email = userEmail();
    setIsConfirming(true);

    MySwal.fire({
      title: "Delete Chat History",
      text: "Are you sure you?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "customDangerBtn",
        cancelButton: "customCancelBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the delete action
        deleteMsg(email);
      } else {
        setIsConfirming(false);
      }
    });
  };
  useEffect(() => {
    if (deleteMsgInfo.isSuccess) {
      MySwal.fire("Deleted!", "Your data has been deleted.", "success");
      dispatch(setPrevSelectedConversation(selectedConversation));

      dispatch(setSelectedConversation("nofetch"));
    }
  }, [deleteMsgInfo.isSuccess, dispatch]);
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/logout");
  };

  return (
    <>
      {/* <button
        className="btn btn-light text-primary-white  text-primary-black gap-2 pl-6 inline-flex items-center h-full w-full rounded-md"
        onClick={handleClick}
      >
        <TrashIcon width="20" height="20" />
        <span className="small text-nowrap mt-n1 font-medium">
          Clear Archive
        </span>
      </button>
      <button className="btn btn-light text-primary-white  text-primary-black gap-2 pl-6 inline-flex items-center h-full w-full rounded-md">
        <TrashIcon width="20" height="20" />
        <span className="small text-nowrap mt-n1 font-medium">Logout</span>
      </button> */}
      <div className="flex space-x-4 justify-center mx-4">
        <button
          className="flex items-center bg-white text-black px-4 py-2 rounded flex-col flex-1 shrink-0 w-0"
          onClick={handleLogout}
        >
          <PersonUpIcon width="20" height="20" className="mr-1" />
          <span className="small text-nowrap font-medium">Logout</span>
        </button>

        <button
          className="flex items-center bg-white text-black px-4 py-2 rounded flex-col flex-1 shrink-0 w-0"
          onClick={handleClick}
        >
          <TrashIcon width="20" height="20" className="mr-1" />
          <span className="small text-nowrap font-medium">Clear Archive</span>
        </button>
        {/* <button className="bg-white text-black px-4 py-2 rounded ">
          <span className="mx-auto">
            <PersonUpIcon width="20" height="20" />
          </span>

          <span className="small text-nowrap mt-n1 font-medium">Logout</span>
        </button>
        <button
          className="bg-white text-black px-4 py-2 rounded"
          onClick={handleClick}
        >
          <span className="mx-auto">
            <TrashIcon width="20" height="20" />
          </span>

          <span className="small text-nowrap mt-n1 font-medium">
            Clear Archive
          </span>
        </button> */}
      </div>
    </>
  );
};

export default SidebarBtns;
