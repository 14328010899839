import React, { useState } from "react";

import Sidebar from "../../Components/SideBar/Sidebar";
import Cookies from "js-cookie";

import MessageWrapper from "../../Components/MessageWrapper/MessageWrapper";
import { useLocation } from "react-router-dom";
import SocketWrapper from "../../Components/SocketWrapper/SocketWrapper";

const HomeScreen = ({ showSidebar, setShowSidebar }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const myParam = params.get("id");
  

  // const isStdBot = Cookies.get("activeConfigName");


  // if(myParam)  {
  //   Cookies.set("requestBased", "socket", { expires: 365 })
  // }
  // else if( isStdBot==="Standard Bot"){
  //   Cookies.set("requestBased", "http", { expires: 365 })
  // }


  const requestBased = Cookies.get("requestBased");

  const [showWelcome, setShowWelcome] = useState(myParam ? true : false);
  return (
    <div className="overflow-hidden">
      <div className=" flex flex-row h-screen">
        {/* {!myParam && ( */}
          <Sidebar
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            setShowWelcome={setShowWelcome}
            
          />
        {(requestBased === "socket" || myParam ) ? <SocketWrapper menuOpen={showSidebar}
          showWelcome={showWelcome}
          setShowWelcome={setShowWelcome}
          myParam={myParam} />:<MessageWrapper menuOpen={showSidebar}
          showWelcome={showWelcome}
          setShowWelcome={setShowWelcome}
          myParam={myParam} />}
        
      </div>
    </div>
  );
};

export default HomeScreen;
