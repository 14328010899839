export const envTypes = {
  development: "development",
  staging: "staging",
  production: "production",
};

// const themes = {
//   kfcTheme: "kfcTheme",
//   pizzaTheme: "pizzaTheme",
// };
const env = envTypes.production;
let dfpBaseUrl, dfpApi, subDomain, loginPath, domain, socketUrl, baseUrl;

export const skipLogin = env === "development" ? true : false;

if (env === envTypes.development) {
  dfpBaseUrl = "http://localhost:8006/rest/v2/realm/";
  loginPath = "http://localhost:8006/login";
  baseUrl = "http://localhost:8006";
  domain = "http://localhost:3000/";
  socketUrl = "ws://localhost:8006/rest/v2/realm/ws";
}
if (env === envTypes.staging) {
  dfpBaseUrl = "https://dfp-api.nonprod.yumds.com/rest/v2/realm/";
  loginPath = "https://dfp-api.nonprod.yumds.com/login";
  socketUrl = "wss://dfp-api.nonprod.yumds.com/rest/v2/realm/ws";
  baseUrl = "https://dfp-api.nonprod.yumds.com";
}

if (env === envTypes.production) {
  dfpBaseUrl = "https://dfp-api.yumds.com/rest/v2/realm/";
  dfpApi = "https://dfp-api.yumds.com/rest/v2/";
  // subDomain = "/dfp-admin";
  loginPath = "https://dfp-api.yumds.com/login";
  domain = "https://dfp.yumds.com/";
  socketUrl = "wss://dfp-api.yumds.com/rest/v2/realm/ws";
  baseUrl = "https://dfp-api.yumds.com";
}
// export const currentTheme = themes.kfcTheme;
export {
  dfpBaseUrl,
  dfpApi,
  subDomain,
  loginPath,
  domain,
  socketUrl,
  env,
  baseUrl,
};
